import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import About from "../components/About/About";
import config from "../../data/SiteConfig";
import Members from "../components/Members";

function AboutPage(props) {
  const { memberData } = props.pageContext;
  return (
    <Layout>
      <Helmet title={`About | ${config.siteTitle}`} />
      <Members memberData={memberData} />
    </Layout>
  );
}

export default AboutPage;
